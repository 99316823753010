import { StripePricingData } from '../../../../store/auth/auth.types';

interface PlanInfo {
  name: string;
  productId: string;
  price: {
    monthly: {
      priceId: string;
      price: string;
    };
    yearly: {
      priceId: string;
      price: string;
    };
  };
  priceText: {
    monthly: string;
    yearly: string;
  };
  description: string;
  maxSeats: number;
  featuresTitle: string;
  features: string[];
}

interface PlanInfoResult {
  starter: PlanInfo;
  professional: PlanInfo;
  scale: PlanInfo;
}

export const getPlansData = (data: StripePricingData): PlanInfoResult => {
  const { starter, professional, scale } = data;

  const starterInfo = {
    name: 'Starter',
    productId: starter.productId,
    price: {
      monthly: starter.price.monthly,
      yearly: starter.price.yearly,
    },
    priceText: {
      monthly: 'Per user/month Billed monthly',
      yearly: 'Per user / year',
    },
    description: 'Best for SDRs & AEs',
    maxSeats: 3,
    featuresTitle: 'Features:',
    features: [
      'AI Message Wizard',
      'X-Ray mode',
      'Response Simulator',
      'Authority Analysis',
      'Upper-Hand Analysis',
    ],
  };

  const professionalInfo = {
    name: 'Professional',
    productId: professional.productId,
    price: {
      monthly: professional.price.monthly,
      yearly: professional.price.yearly,
    },
    priceText: {
      monthly: 'Per user/month Billed monthly',
      yearly: 'Per user / year',
    },
    maxSeats: 10,
    description: 'Seasoned GTM Professionals ',
    featuresTitle: 'Everything in Starter, plus:',
    features: [
      'Team Management',
      'Performance Analytics',
      'Webhooks',
      'Prospect Data Enrichment',
      'Event notifications',
    ],
  };

  const scaleInfo = {
    name: 'Scale',
    productId: scale.productId,
    price: {
      monthly: scale.price.monthly,
      yearly: scale.price.yearly,
    },
    priceText: {
      monthly: 'Per user/month Billed monthly',
      yearly: 'Per user / year',
    },
    description: 'Large Sales & Business\n Development Crews',
    maxSeats: 30,
    featuresTitle: 'Everything in Professional, plus:',
    features: [
      'Organization management',
      'CRM Integrations',
      'Team Analytics',
      'Workflow Integrations',
      'Customer Success',
    ],
  };

  return {
    starter: starterInfo,
    professional: professionalInfo,
    scale: scaleInfo,
  };
};
