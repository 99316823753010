import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components';
import { substrataLogo } from './assets';

interface Props {
  isInactive?: boolean;
  googleSignIn?: () => void;
}

export const LoginContent: React.FC<Props> = ({
  isInactive = false,
  googleSignIn = () => {},
}): JSX.Element => {
  const history = useHistory();

  const goToSelfSignup = () => {
    history.push('./signup');
  };

  const openSubstrata = () => {
    window.open('https://www.substrata.me/company/about/', '_blank');
  };

  const openPrivacyPolicy = () => {
    window.open('https://www.substrata.me/privacy-policy/', '_blank');
  };

  const openTemsOfService = () => {
    window.open('https://www.substrata.me/terms-of-service/', '_blank');
  };

  const attrs = {
    wrapper: {
      className: `${isInactive ? 'inactive' : ''} login-content`,
    },

    body: {
      wrapper: {
        className: 'login-content__body',
      },

      title: {
        className: 'login-content__body-title',
      },

      subtitle: {
        className: 'login-content__body-subtitle',
      },

      googleSection: {
        wrapper: {
          className: 'login-content__body__google',
        },

        button: {
          isGoogleSignIn: true,
          onClick: googleSignIn,
        },

        footer: {
          wrapper: {
            className: 'login-content__body__google__footer',
          },

          text: {
            className: 'login-content__body__google__footer-text',
          },

          link: {
            className: 'login-content__body__google__footer-link',
            onClick: goToSelfSignup,
          },
        },
      },
    },

    footer: {
      wrapper: {
        className: 'login-content__footer',
      },

      link: {
        substrata: {
          className: 'login-content__footer-link',
          src: substrataLogo,
          onClick: openSubstrata,
        },

        privacy: {
          className: 'login-content__footer-link',
          onClick: openPrivacyPolicy,
        },

        terms: {
          className: 'login-content__footer-link',
          onClick: openTemsOfService,
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.body.wrapper}>
        <div {...attrs.body.title}>Welcome back.</div>
        <div {...attrs.body.subtitle}>
          Log in to improve your win-rate in real-time with email behavioral
          intelligence.
        </div>
        <div {...attrs.body.googleSection.wrapper}>
          <Button {...attrs.body.googleSection.button}>
            Continue with Google
          </Button>
          <div {...attrs.body.googleSection.footer.wrapper}>
            <div {...attrs.body.googleSection.footer.text}>
              Don&apos;t have an account yet?
            </div>
            <div {...attrs.body.googleSection.footer.link}>Sign up</div>
          </div>
        </div>
      </div>
      <div {...attrs.footer.wrapper}>
        <div {...attrs.footer.link.substrata} />
        <div {...attrs.footer.link.privacy}>Privacy Policy</div>
        <div {...attrs.footer.link.terms}>Terms of Service</div>
      </div>
    </div>
  );
};
