import { useState } from 'react';
import ReactGa4 from 'react-ga4';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

type GAEventType = 'pageview' | 'event';

interface ReturnType {
  trackGAEvent: (eventType: GAEventType, value: string) => void;
  isGoogleAnalyticsInitialized: boolean;
}

export const useGoogleAnalytics = (): ReturnType => {
  const { isGoogleAnalyticsInitialized } = useSelector(
    (state: RootState) => state.auth
  );

  const [lastSentEventValue, setLastSentEventValue] = useState('');

  const trackGAEvent = (eventType: GAEventType, value: string) => {
    if (isGoogleAnalyticsInitialized && value.length) {
      try {
        if (value !== lastSentEventValue) {
          setLastSentEventValue(value);

          ReactGa4.event({
            category: eventType,
            action: value,
          });
          // console.log(eventType, value);
        }
        // eslint-disable-next-line
      } catch (error) {
        // catch crash if GA4 is not initialized
      }
    }
  };

  return {
    trackGAEvent,
    isGoogleAnalyticsInitialized,
  };
};
