import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { refreshToken } from '../../store/login/login.actions';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import {
  avatarSteinberg,
  linkIcon,
  logo,
  substrataTextImg,
  textWithQuotes,
} from './assets';
import { uninstallExtension } from '../../store/auth/auth.actions';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';

export const UnistallExtension: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);

  const { isMixPanelInitialized, uninstallExtensionSent } = useSelector(
    (state: RootState) => state.auth
  );

  const { trackEvent } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(refreshToken());
    }

    if (isMixPanelInitialized) {
      trackEvent('uninstall-extension');
    }

    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('event', 'uninstall-extension');
    }
    // eslint-disable-next-line
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  useEffect(() => {
    if (user && !uninstallExtensionSent) {
      const { orgId, userId } = user;

      const currentTime = new Date().toISOString();

      dispatch(uninstallExtension(orgId, userId, currentTime, '6.3.5'));
    }
  }, [uninstallExtensionSent]); // eslint-disable-line

  const onGoToStoreClick = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
      '_blank'
    );
  };

  const onContactUsLinkClick = () => {
    window.open('https://www.substrata.me/contact', '_blank');
  };

  const attrs = {
    wrapper: {
      className: 'uninstall',
    },

    header: {
      wrapper: {
        className: 'uninstall__header',
      },

      logo: {
        className: 'uninstall__header-logo',
        src: substrataTextImg,
      },

      button: {
        className: 'uninstall__header-button',
        onClick: onGoToStoreClick,
      },
    },

    body: {
      wrapper: {
        className: 'uninstall__body',
      },

      restoreSection: {
        wrapper: {
          className: 'uninstall__body__restore',
        },

        boldRow: {
          wrapper: {
            className: 'uninstall__body__restore__bold-row',
          },

          logo: {
            className: 'uninstall__body__restore__bold-row-logo',
            src: logo,
          },
        },

        link: {
          wrapper: {
            className: 'uninstall__body__restore__link',
            onClick: onContactUsLinkClick,
          },

          icon: {
            className: 'uninstall__body__restore__link-icon',
            src: linkIcon,
          },
        },

        actionRow: {
          wrapper: {
            className: 'uninstall__body__restore__action',
          },

          link: {
            className: 'uninstall__body__restore__action-link',
            onClick: onGoToStoreClick,
          },
        },
      },

      messageSection: {
        wrapper: {
          className: 'uninstall__body__message',
        },

        textImg: {
          className: 'uninstall__body__message-text-img',
          src: textWithQuotes,
        },

        user: {
          wrapper: {
            className: 'uninstall__body__message__user',
          },

          avatar: {
            img: {
              className: 'uninstall__body__message__user-avatar',
              src: avatarSteinberg,
            },

            custom: {
              className: 'uninstall__body__message__user-custom-avatar',
            },
          },

          info: {
            wrapper: {
              className: 'uninstall__body__message__user__info',
            },

            name: {
              className: 'uninstall__body__message__user__info-name',
            },

            title: {
              className: 'uninstall__body__message__user__info-title',
            },
          },
        },
      },
    },
  };

  const userToShow = user ? (
    <div {...attrs.body.messageSection.user.wrapper}>
      <img {...attrs.body.messageSection.user.avatar.img} alt="ava" />
      <div {...attrs.body.messageSection.user.info.wrapper}>
        <div {...attrs.body.messageSection.user.info.name}>Max Steinberg</div>
        <div {...attrs.body.messageSection.user.info.title}>CEO, LeadHives</div>
      </div>
    </div>
  ) : (
    <></>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header.wrapper}>
        <img {...attrs.header.logo} alt="" />
        <div {...attrs.header.button}>Reinstall extension</div>
      </div>
      <div {...attrs.body.wrapper}>
        <div {...attrs.body.restoreSection.wrapper}>
          <div {...attrs.body.restoreSection.boldRow.wrapper}>
            <img {...attrs.body.restoreSection.boldRow.logo} alt="" />
            We&apos;re sorry to see you go.
          </div>

          <div {...attrs.body.restoreSection.actionRow.wrapper}>
            Didn&apos;t mean to uninstall?
            <div {...attrs.body.restoreSection.actionRow.link}>
              Click here to restore.
            </div>
          </div>

          <div {...attrs.body.restoreSection.link.wrapper}>
            Contact us if you have any questions{' '}
            <img {...attrs.body.restoreSection.link.icon} alt="" />
          </div>
        </div>

        <div {...attrs.body.messageSection.wrapper}>
          <img {...attrs.body.messageSection.textImg} alt="" />
          <div {...attrs.body.messageSection.user}>{userToShow}</div>
        </div>
      </div>
    </div>
  );
};
