import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import packageInfo from '../package.json';
import './App.scss';
import { SignupRouter } from './SignupRouter';
import { store } from './store/store';
import { Login } from './pages/Login/Login';
import { GoogleCallbackLogin } from './pages/Login/GoogleCallbackLogin';
import { Logout } from './pages/Logout/Logout';
import { ROUTES } from './routes';
import { MixPanel } from './components/MixPanel/MixPanel';
import { UnistallExtension } from './pages/UnistallExtension/UnistallExtension';
import { SelfSignupRouter } from './SelfSignupRouter';
import { GoogleAnalytics } from './components/GoogleAnalytics/GoogleAnalytics';

const App: React.FC = (): JSX.Element => {
  console.log(packageInfo.version);

  return (
    <Router>
      <Provider store={store}>
        <div className="page">
          <div className="page__content">
            <Switch>
              <Route path={ROUTES.login}>
                <Login />
              </Route>
              <Route path={ROUTES.logout}>
                <Logout />
              </Route>
              <Route exact path={ROUTES.googleLogin}>
                <GoogleCallbackLogin />
              </Route>
              <Route path={ROUTES.uninstall}>
                <UnistallExtension />
              </Route>
              <Route
                path={[
                  ROUTES.signup.createAccount,
                  ROUTES.signup.googleSignup,
                  ROUTES.signup.addExtension,
                ]}
              >
                <SignupRouter />
              </Route>
              <Route
                path={[
                  ROUTES.selfSignup.signup,
                  ROUTES.selfSignup.homePage,
                  ROUTES.selfSignup.googleSignup,
                  ROUTES.selfSignup.pricing,
                  ROUTES.selfSignup.paymentDetails,
                  ROUTES.selfSignup.processing,
                  ROUTES.selfSignup.addExtension,
                ]}
              >
                <SelfSignupRouter />
              </Route>
            </Switch>
            <div className="page__content-version">
              Ver {packageInfo.version}
            </div>
          </div>
        </div>
        <MixPanel />
        <GoogleAnalytics />
      </Provider>
    </Router>
  );
};

export default App;
