import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { ACCOUNT_URL, SIGNUP_URL } from '../../../constants/config';
import { signInWithGoogle } from '../../../store/login/login.actions';
import firstImg from '../assets/help-first.svg';
import secondImg from '../assets/help-second.svg';
import thirdImg from '../assets/help-third.svg';
import { Loader } from '../../../components/Loader/Loader';
import { ROUTES } from '../../../routes';

export const AddExtension: React.FC = (): JSX.Element => {
  const {
    authCode,
    isGettingAuthCode,
    user,
    isSignInLoading,
    authError,
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const history = useHistory();

  const [googleAuthToken, setGoogleAuthToken] = useState('');

  const ifrRefForSignUp = useRef<HTMLIFrameElement>(null);

  // eslint-disable-next-line
  window.onmessage = (event: any) => {
    if (event.origin !== SIGNUP_URL) {
      return;
    }
    if (event.data) {
      setGoogleAuthToken(event.data);
    }
  };

  useEffect(() => {
    if (!authError && !user && googleAuthToken?.length) {
      dispatch(signInWithGoogle(googleAuthToken, 'signup'));
    } else if (authError) history.push(ROUTES.selfSignup.homePage);
    // eslint-disable-next-line
  }, [googleAuthToken, authError, user, dispatch]);

  useEffect(() => {
    if (authCode && localStorage.getItem('token')) {
      const authCodeArray = authCode.split('-');
      const accountAuthCodeObj =
        authCodeArray.length === 5
          ? {
              a42: [
                authCodeArray[4],
                authCodeArray[0],
                authCodeArray[1],
                authCodeArray[3],
                authCodeArray[2],
              ],
            }
          : null;
      let redirectTimer: NodeJS.Timeout;
      if (ifrRefForSignUp?.current && authCode) {
        setTimeout(() => {
          ifrRefForSignUp.current?.contentWindow?.postMessage(
            accountAuthCodeObj,
            ACCOUNT_URL
          );
          redirectTimer = setTimeout(() => {
            window.location.href =
              'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka';
          }, 1500);
          return () => {
            clearTimeout(redirectTimer);
          };
        }, 1500);
      }
    } // eslint-disable-next-line
  }, [ifrRefForSignUp.current, authCode, user?.jwt, isGettingAuthCode]);

  const attrs = {
    pageWrapper: {
      className: 'add-extension',
    },
    helpColumn: {
      className: 'add-extension__help',
    },
    helpBlock: {
      className: 'add-extension__help-block',
    },
    dot: {
      className: 'add-extension__help-block__dot',
    },
    text: {
      className: 'add-extension__help-block__text',
    },
    imgs: {
      first: {
        src: firstImg,
        className: 'add-extension__help-block__img',
      },
      second: {
        src: secondImg,
        className: 'add-extension__help-block__img',
      },
      third: {
        src: thirdImg,
        className: 'add-extension__help-block__img',
      },
    },
    googleColumn: {
      className: 'add-extension__google-column',
    },
    iframe: {
      src: `${ACCOUNT_URL}?restrictRedirect=true`,
      ref: ifrRefForSignUp,
      className: 'iframe',
    },
    loader: {
      isLoading: isSignInLoading,
      isSignup: true,
    },
  };

  return (
    <div {...attrs.pageWrapper}>
      <div {...attrs.helpColumn}>
        <div {...attrs.helpBlock}>
          <span {...attrs.dot} />
          <span {...attrs.text}>
            On the right, choose the Google account to which you received your
            invitation.
          </span>
          <img {...attrs.imgs.first} alt="" />
        </div>
        <div {...attrs.helpBlock}>
          <span {...attrs.dot} />
          <span {...attrs.text}>Then Click ‘Allow’ at the bottom.</span>
          <img {...attrs.imgs.second} alt="" />
        </div>
        <div {...attrs.helpBlock}>
          <span {...attrs.dot} />
          <span {...attrs.text}>
            Finally, Add to Chrome from the Chrome Web Store.
          </span>
          <img {...attrs.imgs.third} alt="" />
        </div>
      </div>
      <div {...attrs.googleColumn} />
      <iframe {...attrs.iframe} title="b1" />
      <Loader {...attrs.loader} />
    </div>
  );
};
