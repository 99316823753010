import { Reducer } from 'redux';
import {
  GET_GOOGLE_SIGN_UP_URL,
  SET_CHOOSED_PLAN_INFO,
  SET_GA_IS_INITIALIZED,
  SET_GOOGLE_SS_URL,
  SET_MIX_PANEL_IS_INITIALIZED,
  SET_PAYMENT_STATUS,
  SET_TRIAL_USED_STATUS,
  UNINSTALL_EXTENSION,
  VERIFY_TOKEN_TO_SIGNUP,
  VERIFY_TOKEN_TO_SIGNUP_FAILURE,
} from './auth.actions';
import {
  AuthActions,
  ChoosedPlanInfo,
  PaymentStatus,
  StripePricingData,
  VerifiedInformation,
} from './auth.types';
import {
  STRIPE_PROF_MONTHLY_PRICE_ID,
  STRIPE_PROF_PRODUCT_ID,
  STRIPE_PROF_YEARLY_PRICE_ID,
  STRIPE_SCALE_MONTHLY_PRICE_ID,
  STRIPE_SCALE_PRODUCT_ID,
  STRIPE_SCALE_YEARLY_PRICE_ID,
  STRIPE_STARTER_MONTHLY_PRICE_ID,
  STRIPE_STARTER_PRODUCT_ID,
  STRIPE_STARTER_YEARLY_PRICE_ID,
} from '../../constants/config';

const plansData = {
  starter: {
    productId: STRIPE_STARTER_PRODUCT_ID,
    price: {
      monthly: {
        priceId: STRIPE_STARTER_MONTHLY_PRICE_ID,
        price: '29',
      },
      yearly: {
        priceId: STRIPE_STARTER_YEARLY_PRICE_ID,
        price: '279',
      },
    },
  },

  professional: {
    productId: STRIPE_PROF_PRODUCT_ID,
    price: {
      monthly: {
        priceId: STRIPE_PROF_MONTHLY_PRICE_ID,
        price: '49',
      },
      yearly: {
        priceId: STRIPE_PROF_YEARLY_PRICE_ID,
        price: '469',
      },
    },
  },

  scale: {
    productId: STRIPE_SCALE_PRODUCT_ID,
    price: {
      monthly: {
        priceId: STRIPE_SCALE_MONTHLY_PRICE_ID,
        price: '99',
      },
      yearly: {
        priceId: STRIPE_SCALE_YEARLY_PRICE_ID,
        price: '949',
      },
    },
  },
};

export type AuthState = {
  dataToSignUp: VerifiedInformation | null;
  error: boolean;
  googleUrl: string | null;
  googleSelfSignupUrl: string | null;
  isMixPanelInitialized: boolean;
  isGoogleAnalyticsInitialized: boolean;
  uninstallExtensionSent: boolean;
  plansAndPricing: StripePricingData;
  choosedPlan: ChoosedPlanInfo | null;
  paymentStatus: PaymentStatus | null;
  trialUsedStatus: boolean;
};

const initialState: AuthState = {
  dataToSignUp: null,
  error: false,
  googleUrl: null,
  googleSelfSignupUrl: null,
  isMixPanelInitialized: false,
  isGoogleAnalyticsInitialized: false,
  uninstallExtensionSent: false,
  plansAndPricing: plansData,
  choosedPlan: null,
  paymentStatus: null,
  trialUsedStatus: false,
};

export const authReducer: Reducer<AuthState, AuthActions> = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case VERIFY_TOKEN_TO_SIGNUP:
      return {
        ...state,
        dataToSignUp: action.payload,
      };

    case GET_GOOGLE_SIGN_UP_URL:
      return {
        ...state,
        googleUrl: action.payload,
      };

    case SET_GOOGLE_SS_URL:
      return {
        ...state,
        googleSelfSignupUrl: action.payload,
      };

    case VERIFY_TOKEN_TO_SIGNUP_FAILURE:
      return {
        ...state,
        error: true,
      };

    case SET_MIX_PANEL_IS_INITIALIZED:
      return {
        ...state,
        isMixPanelInitialized: true,
      };

    case SET_GA_IS_INITIALIZED:
      return {
        ...state,
        isGoogleAnalyticsInitialized: true,
      };

    case UNINSTALL_EXTENSION:
      return {
        ...state,
        uninstallExtensionSent: true,
      };

    case SET_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case SET_CHOOSED_PLAN_INFO:
      return {
        ...state,
        choosedPlan: action.payload,
      };
    case SET_TRIAL_USED_STATUS:
      return {
        ...state,
        trialUsedStatus: action.payload,
      };
    default:
      return state;
  }
};
