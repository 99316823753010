// prettier-ignore
import { Dispatch } from 'redux';
import axios from '../../axios';
import {
  ClearLoginErrors,
  ExtensionMessageLogin,
  GetGoogleChooseAccountUrlAction,
  GetGoogleSignInUrlAction,
  SignInFailureAction,
  SignInLoading,
} from './login.types';
import {
  EXTENSION_ID,
  MINIMUM_EXTENSION_VERSION,
} from '../../constants/config';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_GOOGLE_CHOOSE_ACCOUNT_URL = 'GET_GOOGLE_CHOOSE_ACCOUNT_URL';
export const GET_GOOGLE_SIGN_IN_URL = 'GET_GOOGLE_SIGN_IN_URL';
export const IS_GETTING_AUTH_CODE = 'IS_GETTING_AUTH_CODE';
export const AUTH_CODE_OBTAINED = 'AUTH_CODE_OBTAINED';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const EXTENSION_MESSAGE_LOGIN = 'LOGIN';
export const EXTENSION_MESSAGE_LOGOUT = 'LOGOUT';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';

export const setDefaultJWT = (jwt: string): void => {
  const checkedJwt = jwt.includes('Bearer') ? jwt : `Bearer ${jwt}`;

  axios.defaults.headers.common.Authorization = checkedJwt;
};

export const getExtensionAuthCode = async (): Promise<void> => {
  try {
    const call = await axios.get('/auth-code/get?client=extension');
    const { authCode } = call.data.payload;
    if (authCode && chrome?.runtime) {
      const message: ExtensionMessageLogin = {
        type: EXTENSION_MESSAGE_LOGIN,
        payload: {
          authCode,
          minimumExtensionVersion: MINIMUM_EXTENSION_VERSION,
        },
      };

      chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
        if (response) {
          console.log('response from extension 47', response);
        } else {
          console.log('sendMesage error 49', chrome.runtime.lastError);
        }
      });
    }
  } catch (e) {
    // TODO handle error
    console.log('from catch getExtensionAuthCode', e);
  }
};

export const setSinginLoading = (bool: boolean): SignInLoading => ({
  type: SIGN_IN_LOADING,
  payload: bool,
});

export const clearLoginErrors = (): ClearLoginErrors => ({
  type: CLEAR_LOGIN_ERRORS,
});

export const getClientAndExtensionAuthCodes = (client = 'dashboard') => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: IS_GETTING_AUTH_CODE });

    const clientAuthCodeResponse = await axios.get(
      `/auth-code/get?client=${client}`
    );

    const { authCode: clientAuthCode } = clientAuthCodeResponse.data.payload;

    dispatch({
      type: AUTH_CODE_OBTAINED,
      payload: { authCode: clientAuthCode },
    });
    console.log('line 84 - clientAuthCodeObtained');
    const extensionAuthCodeResponse = await axios.get(
      '/auth-code/get?client=extension'
    );

    const {
      authCode: extensionAuthCode,
    } = extensionAuthCodeResponse.data.payload;

    if (!clientAuthCode) {
      console.log('line 94 - !clientAuthCode');
      throw new Error('No client auth code');
    }

    if (!extensionAuthCode) {
      console.log('line 99 - !extensionAuthCode');
      throw new Error('No extension auth code');
    }

    if (!chrome?.runtime) {
      console.log('line 104 - !chrome?.runtime');
      throw new Error('No chrome runtime');
    }

    if (extensionAuthCode) {
      console.log('line 109 - extensionAuthCode');
      const message: ExtensionMessageLogin = {
        type: EXTENSION_MESSAGE_LOGIN,
        payload: {
          authCode: extensionAuthCode,
          minimumExtensionVersion: MINIMUM_EXTENSION_VERSION,
        },
      };

      chrome?.runtime?.sendMessage(EXTENSION_ID, message, (response) => {
        if (response) {
          console.log('response from extension 110', response);
        } else {
          console.log('sendMesage error 112', chrome.runtime.lastError);
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getGoogleChooseAccountUrl = () => async (
  dispatch: Dispatch<GetGoogleChooseAccountUrlAction>
): Promise<void> => {
  try {
    const response = await axios.get('/login/google');

    dispatch({
      type: GET_GOOGLE_CHOOSE_ACCOUNT_URL,
      payload: `${response.data.payload.url}`,
    });
  } catch (e) {
    // err
  }
};

export const getGoogleSignInUrl = (url: string) => async (
  dispatch: Dispatch<GetGoogleSignInUrlAction | SignInFailureAction>
): Promise<void> => {
  try {
    const conditionalRequestUrl = localStorage.getItem('selfSignup')
      ? `/google-callback/signup/self${url}`
      : `/google-callback/login${url}`;

    const response = await axios.get(conditionalRequestUrl);

    const checkScope = await axios.get(`${response.data.result.payload.url}`);

    const isUserAccountAlreadyExists = response?.data?.result?.payload?.url?.includes(
      'selfSignupInterrupted=1'
    );

    if (isUserAccountAlreadyExists) {
      localStorage.setItem('selfSignupInterrupted', 'true');
    }

    dispatch({
      type: GET_GOOGLE_SIGN_IN_URL,
      payload: `${checkScope.data.payload.url}`,
    });
  } catch (e) {
    localStorage.removeItem('googleAuthCode');

    console.log('from catch getGoogleSignInUrl', e);

    dispatch({
      type: SIGN_IN_FAILURE,
    });
  }
};

export const signInWithGoogle = (
  googleAuthCode: string,
  signup?: 'signup'
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({
      type: SIGN_IN_LOADING,
      payload: true,
    });

    const userResponse = await axios.get(
      `/google-callback/login${googleAuthCode}`
    );

    const user = userResponse.data.result.payload;

    localStorage.setItem('refresh', user.refreshJWTToken);
    localStorage.setItem('token', user.jwt);
    localStorage.setItem('tokenExpiresAt', user.jwtExpiresAt);
    localStorage.removeItem('googleSignUpAuthCode');

    setDefaultJWT(`Bearer ${localStorage.getItem('token')}`);

    const getDashboardAuthCode = await axios.get(
      '/auth-code/get?client=dashboard'
    );

    const { authCode } = getDashboardAuthCode.data.payload;

    if (authCode?.length) {
      localStorage.setItem('authCode', authCode);
    }

    if (!signup) {
      await getExtensionAuthCode();
    }

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        authCode,
        token: user.jwt,
        tokenExpiresAt: user.jwtExpiresAt,
      },
    });
  } catch (e) {
    localStorage.removeItem('googleAuthCode');
    console.log('from catch signInWithGoogle 225', e);

    dispatch({
      type: SIGN_IN_FAILURE,
    });
  }
};

export const refreshToken = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const refresh = localStorage.getItem('refresh');

    if (!refresh) return;

    const respose = await axios.post('/refresh-token', {
      refreshToken: refresh,
    });

    const user = respose.data.payload;

    localStorage.setItem('refresh', user.refreshJWTToken);
    localStorage.setItem('token', user.jwt);
    localStorage.setItem('tokenExpiresAt', user.jwtExpiresAt);

    setDefaultJWT(user.jwt);

    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        user,
        token: user.jwt,
        tokenExpiresAt: user.jwtExpiresAt,
      },
    });
  } catch (e) {
    // catch error
  }
};

export const signOut = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    setDefaultJWT(`Bearer ${localStorage.getItem('token')}`);

    await axios.get('/logout');
  } catch (e) {
    // TODO dispatch failure catch 401
  } finally {
    localStorage.clear();

    setDefaultJWT('');

    dispatch({
      type: SIGN_OUT,
      payload: null,
    });
  }
};
