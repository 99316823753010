import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getGoogleSelfSignupUrl } from '../../../store/auth/auth.actions';
import { Button } from '../../../components';
import { linkChevron } from './assets';
import { useMixPanel } from '../../../components/MixPanel/useMixPanel';
import {
  ACCOUNT_URL,
  SIGNUP_URL,
  SUPER_ACCOUNT_URL,
} from '../../../constants/config';
import {
  getClientAndExtensionAuthCodes,
  refreshToken,
  setDefaultJWT,
  setSinginLoading,
  signInWithGoogle,
} from '../../../store/login/login.actions';
import { ROUTES } from '../../../routes';
import { ErrorModal } from '../../Login/components/ErrorModal/ErrorModal';
import { useGoogleAnalytics } from '../../../components/GoogleAnalytics/useGoogleAnalytics';

export const Signup: React.FC = (): JSX.Element => {
  const {
    authCode,
    user,
    isSignInLoading,
    authError,
    token,
    tokenExpiresAt,
    isGettingAuthCode,
  } = useSelector((state: RootState) => state.login);

  const { googleSelfSignupUrl, isMixPanelInitialized } = useSelector(
    (state: RootState) => state.auth
  );

  const [googleAuthCode, setGoogleAuthCode] = useState('');

  const history = useHistory();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const returnUrl = queryParams.get('returnUrl');

  const client = queryParams.get('client');

  const dispatch = useDispatch();

  const { trackEvent } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  const getGoogleUrl = () => {
    if (!googleSelfSignupUrl) {
      dispatch(getGoogleSelfSignupUrl());
    }
  };

  // eslint-disable-next-line
  window.onmessage = (event: any) => {
    if (event.origin !== SIGNUP_URL) {
      return;
    }
    if (event.data && event.data?.itsAMe) {
      setGoogleAuthCode(event.data.googleAuthCode);
    }
  };

  useEffect(() => {
    if (authError && googleAuthCode?.length) {
      setGoogleAuthCode('');
    }
  }, [authError]);

  useEffect(() => {
    if (!authError && !user && googleAuthCode?.length && !authCode) {
      dispatch(signInWithGoogle(googleAuthCode));
    }
    // eslint-disable-next-line
  }, [googleAuthCode, authError, user, dispatch]);

  useEffect(() => {
    getGoogleUrl();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      googleSelfSignupUrl?.length &&
      !returnUrl &&
      !client &&
      !localStorage.getItem('authCode')
    ) {
      localStorage.setItem('selfSignup', 'true');
    }
  }, [googleSelfSignupUrl, returnUrl, client]);

  useEffect(() => {
    let redirectTimer: NodeJS.Timeout;

    const isAccountExists = localStorage.getItem('selfSignupInterrupted');

    if (isAccountExists && authCode) {
      localStorage.removeItem('selfSignupInterrupted');
      localStorage.removeItem('selfSignup');
      window.location.href = `${ACCOUNT_URL}?authCode=${authCode}`;
    } else if (
      authCode &&
      googleAuthCode &&
      localStorage.getItem('selfSignup')
    ) {
      redirectTimer = setTimeout(() => {
        dispatch(setSinginLoading(false));
        history.push(ROUTES.selfSignup.pricing);
      }, 500);
    }

    return () => {
      clearTimeout(redirectTimer);
    };

    // eslint-disable-next-line
  }, [authCode, user, googleAuthCode]);

  // eslint-disable-next-line
  const decodedToken: any = token?.length ? jwtDecode(token) : '';

  const tokenFromLS = localStorage.getItem('token');

  const selfSignupFromLS = localStorage.getItem('selfSignup');

  const authCodeFromLS = localStorage.getItem('authCode');

  useEffect(() => {
    let redirectTimer: NodeJS.Timeout;

    if (!selfSignupFromLS) {
      if (authCodeFromLS && authCode) {
        if (returnUrl && returnUrl === SUPER_ACCOUNT_URL) {
          window.location.href = `${returnUrl}?authCode=${authCode}`;
        } else if (returnUrl?.includes('mail.google.com')) {
          redirectTimer = setTimeout(() => {
            if (decodedToken?.user?.email) {
              const urlToUsersGmail = `https://mail.google.com/mail/u/${decodedToken.user.email}/#inbox`;
              window.location.href = urlToUsersGmail;
            } else {
              window.location.href = returnUrl;
            }
          }, 1000);
        } else {
          window.location.href = `${ACCOUNT_URL}?authCode=${authCode}`;
        }
      }
    }
    return () => {
      clearTimeout(redirectTimer);
    };
    // eslint-disable-next-line
  }, [authCode, token, client, returnUrl, decodedToken, dispatch]);

  useEffect(() => {
    if (tokenFromLS && !selfSignupFromLS && !authCode && !isGettingAuthCode) {
      const expirationDate = new Date(tokenExpiresAt);
      const now = new Date();
      if (now > expirationDate && !client) {
        dispatch(refreshToken());
      } else {
        setDefaultJWT(token);
        if (client) {
          dispatch(getClientAndExtensionAuthCodes(client));
        } else dispatch(getClientAndExtensionAuthCodes());
      }
    } // eslint-disable-next-line
  }, [token, client, isGettingAuthCode]);

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.homepage');
    }
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.homepage');
    }
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  const goToWebsite = () => {
    window.location.href = 'https://www.substrata.me/';
    trackEvent('signup.homepage.go-to-website-clicked');
    trackGAEvent('event', 'signup.homepage.go-to-website-clicked');
  };

  const openContactCenter = () => {
    window.open('https://www.substrata.me/contact', '_blank');
    trackEvent('signup.homepage.open-contact-center-clicked');
    trackGAEvent('event', 'signup.homepage.open-contact-center-clicked');
  };

  const openTermsOfUse = () => {
    window.open('https://www.substrata.me/terms-of-service/', '_blank');
    trackEvent('signup.homepage.open-terms-of-use-clicked');
    trackGAEvent('event', 'signup.homepage.open-terms-of-use-clicked');
  };

  const openPrivacyPolicy = () => {
    window.open('https://www.substrata.me/privacy-policy/', '_blank');
    trackEvent('signup.homepage.open-privacy-policy-clicked');
    trackGAEvent('event', 'signup.homepage.open-privacy-policy-clicked');
  };

  const clickContinue = () => {
    const googleWindowHeight = window.innerHeight / 2.2;

    const leftPosition = window.innerWidth / 2 - 675;

    const topPosition = -window.innerHeight + googleWindowHeight / 2.5;

    const conditionalProperties =
      window.innerWidth > 900
        ? `top=${topPosition} left=${leftPosition} width=450 height=${googleWindowHeight}`
        : ` width=${window.innerWidth} height=${window.innerHeight}`;

    if (tokenFromLS?.length) {
      window.location.href = ACCOUNT_URL;
    } else if (googleSelfSignupUrl?.length) {
      window.open(
        googleSelfSignupUrl,
        '_blank',
        conditionalProperties
        // `top=${topPosition} left=${leftPosition} width=450 height=${googleWindowHeight}`
        // ` width=${window.innerWidth} height=${window.innerHeight}`
      );
    }
  };

  const attrs = {
    wrapper: {
      className: 'signup',
    },

    leftSection: {
      wrapper: {
        className: 'signup__left-section',
      },

      linkBack: {
        wrapper: {
          className: 'signup__left-section__link-back',
          onClick: goToWebsite,
        },

        chevron: {
          className: 'signup__left-section__link-back-chevron',
          src: linkChevron,
        },
      },

      content: {
        wrapper: {
          className: 'signup__left-section__content',
        },

        row: {
          className: 'signup__left-section__content-row',
        },

        google: {
          wrapper: {
            className: 'signup__left-section__content__google',
          },

          button: {
            className: 'signup__left-section__content__google-button',
            onClick: clickContinue,
            isGoogleSignIn: true,
            googleLightVariant: true,
            disabled: isSignInLoading,
          },

          contact: {
            wrapper: {
              className: 'signup__left-section__content__google__contact',
            },

            text: {
              className: 'signup__left-section__content__google__contact-text',
            },

            link: {
              className: 'signup__left-section__content__google__contact-link',
              onClick: openContactCenter,
            },
          },
        },
      },

      footer: {
        wrapper: {
          className: 'signup__left-section__footer',
        },

        text: {
          wrapper: {
            className: 'signup__left-section__footer__text',
          },

          links: {
            termsOfUse: {
              className: 'signup__left-section__footer__text-link',
              onClick: openTermsOfUse,
            },

            privacyPolicy: {
              className: 'signup__left-section__footer__text-link',
              onClick: openPrivacyPolicy,
            },
          },
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'signup__right-section',
      },

      title: {
        className: 'signup__right-section-title',
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.leftSection.wrapper}>
        <div {...attrs.leftSection.linkBack.wrapper}>
          <img {...attrs.leftSection.linkBack.chevron} alt="" /> Back
        </div>

        <div {...attrs.leftSection.content.google.wrapper}>
          <Button {...attrs.leftSection.content.google.button}>
            Continue with Google
          </Button>
          <div {...attrs.leftSection.content.google.contact.wrapper}>
            <div {...attrs.leftSection.content.google.contact.text}>
              No Google account?
            </div>
            <div {...attrs.leftSection.content.google.contact.link}>
              Contact us
            </div>
          </div>
        </div>

        <div {...attrs.leftSection.footer.wrapper}>
          <div {...attrs.leftSection.footer.text.wrapper}>
            By signing up with Google, you agree to our{' '}
            <div {...attrs.leftSection.footer.text.links.termsOfUse}>
              Terms of Service
            </div>{' '}
            and{' '}
            <div {...attrs.leftSection.footer.text.links.privacyPolicy}>
              Privacy Policy
            </div>
          </div>
        </div>
      </div>
      <div {...attrs.rightSection.wrapper} />
      <ErrorModal />
    </div>
  );
};
