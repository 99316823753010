import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMixpanelInitializedSuccess } from '../../store/auth/auth.actions';
import { MIX_PANEL_TOKEN, MODE } from '../../constants/config';
import { SignupRoutes } from '../../SignupRouter';

export const MixPanel = (): null => {
  const { pathname } = useLocation();

  const [applicationName, setApplicationName] = useState('');

  const dispatch = useDispatch();

  const initialize = (id: string) => {
    mixpanel.init(id, { debug: MODE === 'local' });
  };

  const getPageLocationName = () => {
    const currentRoute = Object.values(SignupRoutes).find(
      ({ path }) => path === pathname
    );

    let currentLocation = '';

    if (currentRoute?.routeName) {
      const { routeName } = currentRoute;

      switch (routeName) {
        case 'Create account':
          currentLocation = `${applicationName}.signup`;
          break;
        case 'Add extension':
          currentLocation = `${applicationName}.add_to_gmail`;
          break;
        case 'Error':
          currentLocation = `${applicationName}.not_allowed`;
          break;
        default:
          break;
      }
    } else if (pathname.includes('/login')) {
      currentLocation = `${applicationName}.login`;
    }

    return currentLocation;
  };

  useEffect(() => {
    if (MODE !== 'local') {
      initialize(MIX_PANEL_TOKEN);
      setApplicationName('signup');
      dispatch(setMixpanelInitializedSuccess());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (MODE !== 'local' && applicationName) {
      mixpanel.track(getPageLocationName());
    } // eslint-disable-next-line
  }, [pathname, applicationName]);

  return null;
};
