import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { SIGNUP_URL } from '../../../constants/config';
import { getGoogleSignInUrl } from '../../../store/login/login.actions';
import { RootState } from '../../../store/store';

export const GoogleCallback: React.FC = (): JSX.Element => {
  const { search: googleAuthCodeToSignUp } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => state.auth);
  const { user, googleSignInUrl } = useSelector(
    (state: RootState) => state.login
  );

  useEffect(() => {
    if (
      !error &&
      !user &&
      googleAuthCodeToSignUp &&
      !localStorage.getItem('googleAuthCode') &&
      !googleSignInUrl
    ) {
      localStorage.setItem('googleAuthCode', googleAuthCodeToSignUp);
      dispatch(getGoogleSignInUrl(googleAuthCodeToSignUp));
    } else if (!error && !user && googleSignInUrl) {
      window.location.href = googleSignInUrl;
    } else if (
      !error &&
      !user &&
      !googleSignInUrl &&
      localStorage.getItem('googleAuthCode') !== googleAuthCodeToSignUp
    ) {
      localStorage.removeItem('googleAuthCode');
      if (window?.opener) {
        window.opener.postMessage(
          { googleAuthCode: googleAuthCodeToSignUp, itsAMe: true },
          SIGNUP_URL
        );
        window.close();
      }
    } else if (error) history.push('/login');
  }, [error, user, dispatch, history, googleAuthCodeToSignUp, googleSignInUrl]);
  return <div />;
};
