import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { refreshToken } from '../../store/login/login.actions';
import { pageLogo } from './assets';
import { ROUTES } from '../../routes';

interface Props {
  children: JSX.Element | string;
}

export const SelfSIgnupBg: React.FC<Props> = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.login);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const tokenLS = localStorage.getItem('token');
    const tokenExpiresAtLS = localStorage.getItem('tokenExpiresAt');

    const now = new Date();

    let expirationDate = null;

    if (tokenExpiresAtLS?.length) {
      expirationDate = new Date(tokenExpiresAtLS);
    }

    const isTokenExpired = expirationDate && now > expirationDate;

    if (
      ((tokenLS?.length && !user) || isTokenExpired) &&
      !pathname.includes(ROUTES.selfSignup.paymentDetails)
    ) {
      dispatch(refreshToken());
    }
  }, [pathname]); // eslint-disable-line

  const isCurrentPageCompleteOrder = pathname.includes(
    ROUTES.selfSignup.completeOrder
  );

  const wrapperClassName = isCurrentPageCompleteOrder
    ? 'no-x-overflow self-signup'
    : 'self-signup';

  const contentClassName = isCurrentPageCompleteOrder
    ? 'co-page self-signup__content'
    : 'self-signup__content';

  const attrs = {
    wrapper: {
      className: wrapperClassName,
    },

    logo: {
      wrapper: {
        className: 'self-signup__logo',
      },

      img: {
        className: 'self-signup__logo-img',
        src: pageLogo,
      },
    },

    backgroundImg: {
      className: 'self-signup-background-img',
    },

    content: {
      className: contentClassName,
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.backgroundImg} />

      <div {...attrs.content}>{children}</div>
    </div>
  );
};
