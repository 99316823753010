import React, { useEffect, useState } from 'react';
import { logoSignup } from './assets';
import { LoginLogo } from './components/LoginLogo/LoginLogo';

interface Props {
  isLoading?: boolean;
  isSignup?: boolean;
}

export const Loader: React.FC<Props> = ({
  isLoading = false,
  isSignup = false,
}): JSX.Element => {
  const [footerIsShown, setFooterIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) setFooterIsShown(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  const attrs = {
    wrapper: {
      className: `${!isLoading ? 'hidden ' : ''}${
        isSignup ? '' : 'login '
      }loader`,
    },

    body: {
      login: {
        wrapper: {
          className: 'loader__login',
        },

        logo: {
          className: 'loader__login-logo',
        },

        title: {
          wrapper: {
            className: 'loader__login__title',
          },

          text: {
            className: 'loader__login__title-text',
          },

          animationSection: {
            wrapper: {
              className: 'loader__login__title__animation-section',
            },

            dots: {
              className: 'loader__login__title__animation-section-dots',
            },
          },
        },
      },

      signup: {
        wrapper: {
          className: 'loader__body',
        },

        title: {
          className: 'loader__body-title',
        },

        animatedLogo: {
          className: 'loader__body-logo',
          src: logoSignup,
        },

        link: {
          className: 'loader__body-footer',
        },
      },
    },
  };

  const titleText = isSignup
    ? 'Taking you to the Chrome Web Store'
    : 'Signing you in';

  const conditionalFooter =
    isSignup && footerIsShown ? (
      <div {...attrs.body.signup.link}>
        Still here? Click to go to the{' '}
        <a href="https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka">
          Chrome Web Store
        </a>
        .
      </div>
    ) : null;

  const loginBody = !isSignup ? (
    <div {...attrs.body.login.wrapper}>
      <div {...attrs.body.login.logo}>
        <LoginLogo />
      </div>

      <div {...attrs.body.login.title.wrapper}>
        <div {...attrs.body.login.title.text}>Logging you in</div>
        <div {...attrs.body.login.title.animationSection.wrapper}>
          <div {...attrs.body.login.title.animationSection.dots}>.</div>
        </div>
      </div>
    </div>
  ) : null;

  const signupBody = isSignup ? (
    <div {...attrs.body.signup.wrapper}>
      <div {...attrs.body.signup.title}>{titleText}</div>
      <img {...attrs.body.signup.animatedLogo} alt="" />
      {conditionalFooter}
    </div>
  ) : null;

  return (
    <div {...attrs.wrapper}>
      {loginBody}
      {signupBody}
    </div>
  );
};
