import React from 'react';

export const LoginLogo: React.FC = (): JSX.Element => {
  const greyLogo = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="login-logo__svg"
    >
      <path
        d="M34.8511 30.3173C36.561 27.3081 37.5408 23.7666 37.5408 20C37.5408 8.98669 29.1258 0 18.7704 0C8.41498 0 0 8.96622 0 20C0 31.0338 8.41498 40 18.7704 40C20.8838 40 22.9011 39.6315 24.8031 38.9355C25.4179 38.7103 26.0327 38.4647 26.609 38.1576C26.7435 38.0962 26.878 38.0348 27.0125 37.9529C27.147 37.8915 27.2622 37.8096 27.3967 37.7482C27.6849 37.6049 27.9539 37.4411 28.2229 37.2569C28.3189 37.1955 28.4342 37.1341 28.5303 37.0522C28.7992 36.8884 29.0682 36.7042 29.318 36.4995C29.3756 36.4585 29.414 36.4381 29.4717 36.3971L25.4371 31.6274L17.2719 21.9857L14.4669 18.6694L9.45245 20.7779L20.2498 33.5312C19.7695 33.5926 19.2891 33.6131 18.7896 33.6131C11.7387 33.6131 5.99424 27.4923 5.99424 19.9795C5.99424 12.4667 11.7387 6.34596 18.7896 6.34596C25.8405 6.34596 31.585 12.4667 31.585 19.9795C31.585 21.8424 31.22 23.6233 30.586 25.261L18.3093 10.7677L13.2949 12.8762L33.1988 36.4176H40L34.8511 30.3173Z"
        fill="#4D4558"
      />
    </svg>
  );

  const purpleLogo = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="purple login-logo__svg"
    >
      <path
        d="M34.8511 30.3173C36.561 27.3081 37.5408 23.7666 37.5408 20C37.5408 8.98669 29.1258 0 18.7704 0C8.41498 0 0 8.96622 0 20C0 31.0338 8.41498 40 18.7704 40C20.8838 40 22.9011 39.6315 24.8031 38.9355C25.4179 38.7103 26.0327 38.4647 26.609 38.1576C26.7435 38.0962 26.878 38.0348 27.0125 37.9529C27.147 37.8915 27.2622 37.8096 27.3967 37.7482C27.6849 37.6049 27.9539 37.4411 28.2229 37.2569C28.3189 37.1955 28.4342 37.1341 28.5303 37.0522C28.7992 36.8884 29.0682 36.7042 29.318 36.4995C29.3756 36.4585 29.414 36.4381 29.4717 36.3971L25.4371 31.6274L17.2719 21.9857L14.4669 18.6694L9.45245 20.7779L20.2498 33.5312C19.7695 33.5926 19.2891 33.6131 18.7896 33.6131C11.7387 33.6131 5.99424 27.4923 5.99424 19.9795C5.99424 12.4667 11.7387 6.34596 18.7896 6.34596C25.8405 6.34596 31.585 12.4667 31.585 19.9795C31.585 21.8424 31.22 23.6233 30.586 25.261L18.3093 10.7677L13.2949 12.8762L33.1988 36.4176H40L34.8511 30.3173Z"
        fill="url(#paint0_linear_2265_33803)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2265_33803"
          x1="-6.26263"
          y1="49.5455"
          x2="35.1309"
          y2="59.7519"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.133343" stopColor="#381289" />
          <stop offset="0.718238" stopColor="#A635C6" />
          <stop offset="1" stopColor="#C591D3" />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <div className="login-logo">
      {greyLogo}
      {purpleLogo}
    </div>
  );
};
