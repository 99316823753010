import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { checkPaymentStatus } from '../../../store/auth/auth.actions';
import { paymentErrorIcon } from './assets';
import { ROUTES } from '../../../routes';
import { useMixPanel } from '../../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../../components/GoogleAnalytics/useGoogleAnalytics';

export const Processing: React.FC = (): JSX.Element => {
  const { paymentStatus } = useSelector((state: RootState) => state.auth);

  const { user } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  const history = useHistory();

  const [stopSending, setStopSending] = useState(false);

  const [requestSentTimes, setRequestSentTimes] = useState(0);

  const { trackEvent, isMixPanelInitialized } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.processing-page');
    }

    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.processing-page');
    }
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  useEffect(() => {
    let requestInterval: NodeJS.Timer;

    if (paymentStatus === 'complete') {
      history.push(ROUTES.selfSignup.addExtension);
    }

    if (user) {
      requestInterval = setInterval(() => {
        if (requestSentTimes < 3 && !paymentStatus && !stopSending) {
          dispatch(checkPaymentStatus(user.orgId, user.userId));
          setRequestSentTimes(requestSentTimes + 1);
        } else {
          setStopSending(true);
          clearInterval(requestInterval);
        }
      }, 5000);
    }

    return () => {
      clearInterval(requestInterval);
    }; // eslint-disable-next-line
  }, [user, paymentStatus, stopSending, requestSentTimes]);

  const goToPaymentPage = () => {
    history.push(ROUTES.selfSignup.paymentDetails);
  };

  const attrs = {
    wrapper: {
      className: 'processing',
    },

    leftSection: {
      wrapper: {
        className: 'processing__left-section',
      },

      loading: {
        className: 'processing__left-section-loading',
      },

      error: {
        wrapper: {
          className: 'processing__left-section__error',
        },

        img: {
          className: 'processing__left-section__error-img',
          src: paymentErrorIcon,
        },

        errorMessage: {
          className: 'processing__left-section__error-message',
        },

        text: {
          className: 'processing__left-section__error-text',
        },

        button: {
          className: 'processing__left-section__error-button',
          onClick: goToPaymentPage,
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'processing__right-section',
      },
    },
  };

  const conditionalLeftSectionBody =
    paymentStatus === 'incomplete' ? (
      <div {...attrs.leftSection.error.wrapper}>
        <img {...attrs.leftSection.error.img} alt="" />
        <div {...attrs.leftSection.error.errorMessage}>
          Something went wrong
        </div>
        <div {...attrs.leftSection.error.text}>
          Please go back and try different method
        </div>
        <div {...attrs.leftSection.error.button}>Go to payment page</div>
      </div>
    ) : (
      <div {...attrs.leftSection.loading} />
    );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.leftSection.wrapper}>{conditionalLeftSectionBody}</div>
      <div {...attrs.rightSection.wrapper}>sidebar</div>
    </div>
  );
};
