import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  getGoogleSignInUrl,
  signInWithGoogle,
} from '../../store/login/login.actions';
import { RootState } from '../../store/store';
import { ACCOUNT_URL, SIGNUP_URL } from '../../constants/config';
import { Loader } from '../../components/Loader/Loader';
import { LoginContent } from './components/LoginContent/LoginContent';
import { LoginSidebar } from './components/LoginSidebar/LoginSidebar';

export const GoogleCallbackLogin: React.FC = (): JSX.Element => {
  const {
    user,
    authCode,
    authError,
    isSignInLoading,
    googleSignInUrl,
  } = useSelector((state: RootState) => state.login);

  const { search: googleAuthCode } = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const isGoogleAuthCodeCheck =
      !authError &&
      !user &&
      !localStorage.getItem('googleAuthCode') &&
      !googleSignInUrl &&
      googleAuthCode?.length &&
      !googleAuthCode.includes('webpack');

    const isGoogleSignInUrlCheck = !authError && !user && googleSignInUrl;

    const isStoredAuthCodeNotEqualToQueryCode =
      !authError &&
      !user &&
      !googleSignInUrl &&
      localStorage.getItem('googleAuthCode') !== googleAuthCode;

    const isOpenedInPopupCheck = window?.opener;

    if (isGoogleAuthCodeCheck) {
      localStorage.setItem('googleAuthCode', googleAuthCode);
      dispatch(getGoogleSignInUrl(googleAuthCode));
    } else if (isGoogleSignInUrlCheck) {
      window.location.href = googleSignInUrl;
    } else if (isStoredAuthCodeNotEqualToQueryCode) {
      localStorage.removeItem('googleAuthCode');
      if (isOpenedInPopupCheck) {
        // entering this component after self-signup & invite signup google screen
        window.opener.postMessage({ googleAuthCode, itsAMe: true }, SIGNUP_URL);
        window.close();
      } else {
        dispatch(signInWithGoogle(googleAuthCode));
      }
    } else if (authError) {
      history.push('/login');
    }
  }, [authError, user, dispatch, history, googleAuthCode, googleSignInUrl]);

  useEffect(() => {
    if (authCode) {
      window.location.href = `${ACCOUNT_URL}?authCode=${authCode}`;
    }

    // eslint-disable-next-line
  }, [iframeRef.current, authCode, user]);

  const attrs = {
    loader: {
      isLoading: isSignInLoading,
    },

    iframe: {
      src: `${ACCOUNT_URL}?restrictRedirect=true`,
      ref: iframeRef,
      className: 'iframe',
    },

    loginBackground: {
      wrapper: {
        className: 'login',
      },

      LoginContent: {
        isInactive: true,
      },
    },

    whiteBackground: {
      className: 'login__callback-white-bg',
    },
  };

  const isSelfSignupCheck = localStorage.getItem('selfSignup');

  const loadingBackground = isSignInLoading ? (
    <div {...attrs.loginBackground.wrapper}>
      <LoginContent {...attrs.loginBackground.LoginContent} />
      <LoginSidebar />
    </div>
  ) : null;

  const loader = isSelfSignupCheck ? null : (
    <>
      {loadingBackground}
      <Loader {...attrs.loader} />
    </>
  );

  return (
    <div {...attrs.whiteBackground}>
      {loader}
      {/* <iframe {...attrs.iframe} title="b2" /> */}
    </div>
  );
};
