import React from 'react';
import { chromeIcon, loginSidebarPreview } from './assets';

export const LoginSidebar: React.FC = (): JSX.Element => {
  const openChromeStoreClick = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
      '_blank'
    );
  };

  const attrs = {
    wrapper: {
      className: 'login-sidebar',
    },

    preview: {
      wrapper: {
        className: 'login-sidebar__preview',
      },

      img: {
        className: 'login-sidebar__preview-img',
        src: loginSidebarPreview,
      },
    },

    text: {
      wrapper: {
        className: 'login-sidebar__text-section',
      },

      title: {
        className: 'login-sidebar__text-section-title',
      },

      subtitle: {
        className: 'login-sidebar__text-section-subtitle',
      },
    },

    actionSection: {
      wrapper: {
        className: 'login-sidebar__action-section',
      },

      button: {
        wrapper: {
          className: 'login-sidebar__action-section__button',
          onClick: openChromeStoreClick,
        },

        icon: {
          className: 'login-sidebar__action-section__button-icon',
          src: chromeIcon,
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.preview.wrapper}>
        <img {...attrs.preview.img} alt="" />
      </div>
      <div {...attrs.text.wrapper}>
        <div {...attrs.text.title}>Real-Time Course Correction</div>
        <div {...attrs.text.subtitle}>
          Test the impact of your draft to see if you&apos;re pushing the deal
          forward or making costly mistakes.
        </div>
      </div>
      <div {...attrs.actionSection.wrapper}>
        <div {...attrs.actionSection.button.wrapper}>
          <img {...attrs.actionSection.button.icon} alt="" />
          Get Q for Chrome
        </div>
      </div>
    </div>
  );
};
