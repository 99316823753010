import {
  GET_GOOGLE_SIGN_IN_URL,
  REFRESH_TOKEN,
  SIGN_OUT,
  AUTH_CODE_OBTAINED,
  IS_GETTING_AUTH_CODE,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_LOADING,
  CLEAR_LOGIN_ERRORS,
  GET_GOOGLE_CHOOSE_ACCOUNT_URL,
} from './login.actions';
import { LoginActions } from './login.types';
import { User } from './types';

export type LoginState = {
  user: User | null;
  googleChooseAccountUrl: string;
  googleSignInUrl: string;
  authCode: string;
  isGettingAuthCode: boolean;
  isSignInLoading: boolean;
  authError: boolean;
  token: string;
  tokenExpiresAt: string;
  refresh: string;
};

const initialState: LoginState = {
  user: null,
  googleChooseAccountUrl: '',
  googleSignInUrl: '',
  authCode: '',
  isGettingAuthCode: false,
  isSignInLoading: false,
  authError: false,
  token: localStorage.getItem('token') || '',
  tokenExpiresAt: localStorage.getItem('tokenExpiresAt') || '',
  refresh: localStorage.getItem('refresh') || '',
};

export const loginReducer = (
  state: LoginState = initialState,
  action: LoginActions
): LoginState => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.payload.user,
        authCode: action.payload.authCode,
        token: action.payload.token,
        tokenExpiresAt: action.payload.tokenExpiresAt,
        authError: false,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        authError: true,
        isSignInLoading: false,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        tokenExpiresAt: action.payload.tokenExpiresAt,
      };
    case GET_GOOGLE_CHOOSE_ACCOUNT_URL:
      return {
        ...state,
        googleChooseAccountUrl: action.payload,
      };
    case GET_GOOGLE_SIGN_IN_URL:
      return {
        ...state,
        googleSignInUrl: action.payload,
      };
    case SIGN_OUT:
      return {
        ...initialState,
        token: '',
        tokenExpiresAt: '',
        refresh: '',
      };
    case AUTH_CODE_OBTAINED:
      return {
        ...state,
        authCode: action.payload.authCode,
      };
    case IS_GETTING_AUTH_CODE:
      return {
        ...state,
        isGettingAuthCode: true,
      };
    case SIGN_IN_LOADING:
      return {
        ...state,
        isSignInLoading: action.payload,
      };
    case CLEAR_LOGIN_ERRORS:
      return {
        ...state,
        isGettingAuthCode: false,
        isSignInLoading: false,
        authError: false,
      };
    default:
      return state;
  }
};
