import { useEffect } from 'react';

export const useCheckLSValuesUndefined = () => {
  const tokenLS = localStorage.getItem('token');

  const refreshTokenLS = localStorage.getItem('refresh');

  const tokenExperesAtLS = localStorage.getItem('tokenExpiresAt');

  const checkLSUndefinedValues = () => {
    console.log('checking values from hook');

    if (tokenLS === 'undefined') {
      localStorage.removeItem('token');
    }
    if (refreshTokenLS === 'undefined') {
      localStorage.removeItem('refresh');
    }
    if (tokenExperesAtLS === 'undefined') {
      localStorage.removeItem('tokenExpiresAt');
    }
  };

  useEffect(() => {
    checkLSUndefinedValues();
  }, []);

  return { checkLSUndefinedValues };
};
