import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const SignupRedirectPage: React.FC = (): null => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/`);
  }, [history]);

  return null;
};
