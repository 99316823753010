import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const Login: React.FC = (): JSX.Element => {
  const { search: query } = useLocation();

  const queryParams = new URLSearchParams(query);

  const returnUrl = queryParams.get('returnUrl');

  const client = queryParams.get('client');

  const history = useHistory();

  useEffect(() => {
    if (returnUrl && !client) {
      history.push(`${ROUTES.selfSignup.homePage}?returnUrl=${returnUrl}`);
    } else if (client && !returnUrl) {
      history.push(`${ROUTES.selfSignup.homePage}?client=${client}`);
    } else if (returnUrl && client) {
      history.push(
        `${ROUTES.selfSignup.homePage}?returnUrl=${returnUrl}&client=${client}`
      );
    } else {
      history.push(ROUTES.selfSignup.homePage);
    }
  }, [query, returnUrl, client, history]);

  const attrs = {
    wrapper: {
      className: 'login',
    },
  };

  //  <LoginContent {...attrs.LoginContent} />
  //  <LoginSidebar />
  //  <ErrorModal />

  return <div {...attrs.wrapper} />;
};
