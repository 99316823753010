import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Toggle } from '../../../components/Toggle/Toggle';
import { PlanCard } from './components/PlanCard/PlanCard';
import { FeaturesTable } from './components/FeaturesTable/FeaturesTable';
import { useMixPanel } from '../../../components/MixPanel/useMixPanel';
import { getTrialUsedStatus } from '../../../store/auth/auth.actions';
import { useGoogleAnalytics } from '../../../components/GoogleAnalytics/useGoogleAnalytics';

export const Pricing: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);

  const [toggleActive, setToggleActive] = useState(false);

  const [featuresOpened, setFeaturesOpened] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const topElementRef = useRef<HTMLDivElement>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const { trackEvent, isMixPanelInitialized } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.pricing-page');
    }
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.pricing-page');
    }
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  useEffect(() => {
    if (user) {
      dispatch(getTrialUsedStatus(user.orgId));
    }
  }, [user, dispatch]);

  const toggleFeaturesTable = () => {
    if (!featuresOpened) {
      setFeaturesOpened(true);
      // tableRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      setFeaturesOpened(false);
      topElementRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTheTop = () => {
    topElementRef.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      setFeaturesOpened(false);
    }, 350);
  };

  const attrs = {
    wrapper: {
      className: 'pricing',
    },

    header: {
      wrapper: {
        className: 'pricing__header',
      },

      leftSection: {
        wrapper: {
          className: 'pricing__header__left-section',
        },

        title: {
          className: 'pricing__header__left-section-title',
        },
      },

      rightSection: {
        wrapper: {
          className: 'pricing__header__right-section',
        },

        toggleSection: {
          wrapper: {
            className: 'pricing__header__right-section__toggle-section',
          },

          toggle: {
            className: 'pricing__header__right-section__toggle-section-toggle',
            active: toggleActive,
            onToggle: () => setToggleActive(!toggleActive),
            noStatusLabel: true,
            isDark: windowWidth <= 900,
          },
        },

        text: {
          className: 'pricing__header__right-section-text',
        },
      },
    },

    content: {
      wrapper: {
        className: 'pricing__content',
      },

      refElementAtTheTop: {
        ref: topElementRef,
        className: 'pricing__content-ref-el',
      },

      overflow: {
        className: `${
          featuresOpened ? '' : 'no-scroll'
        } pricing__content-overflow`,
      },

      choosePlan: {
        wrapper: {
          className: 'pricing__content__choose-plan',
        },

        cards: {
          className: 'pricing__content__choose-plan-cards',
        },
      },

      features: {
        wrapper: {
          ref: tableRef,
          className: 'pricing__content__features',
        },

        label: {
          className: `${
            featuresOpened ? 'active' : ''
          } pricing__content__features-label`,
          onClick: toggleFeaturesTable,
        },

        table: {
          className: 'pricing__content__features-table',
          // style: { display: featuresOpened ? 'block' : 'none' },
        },
      },
    },
  };

  const toggleSection = (
    <div {...attrs.header.rightSection.wrapper}>
      <div {...attrs.header.rightSection.toggleSection.wrapper}>
        Monthly
        <Toggle {...attrs.header.rightSection.toggleSection.toggle} />
        Annually
      </div>
      <div {...attrs.header.rightSection.text}>
        Pay annually and save up to 20%
      </div>
    </div>
  );

  const headerToggle = windowWidth > 900 ? toggleSection : null;

  const bodyToggle = windowWidth <= 900 ? toggleSection : null;

  const isMobileResolution = windowWidth <= 900;

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header.wrapper}>
        <div {...attrs.header.leftSection.wrapper}>
          <div {...attrs.header.leftSection.title}>Plans & Pricing</div>
        </div>
        {headerToggle}
      </div>

      <div {...attrs.content.wrapper}>
        <div {...attrs.content.overflow}>
          <div {...attrs.content.refElementAtTheTop} />
          <div {...attrs.content.choosePlan.wrapper}>
            {bodyToggle}
            <div {...attrs.content.choosePlan.cards}>
              <PlanCard
                currentPlan={isMobileResolution ? 'starter' : 'scale'}
                paymentPeriod={toggleActive ? 'yearly' : 'monthly'}
              />
              <PlanCard
                currentPlan="professional"
                paymentPeriod={toggleActive ? 'yearly' : 'monthly'}
              />
              <PlanCard
                currentPlan={isMobileResolution ? 'scale' : 'starter'}
                paymentPeriod={toggleActive ? 'yearly' : 'monthly'}
              />
              <PlanCard currentPlan="enterprise" />
            </div>
          </div>
          <div {...attrs.content.features.wrapper}>
            <div {...attrs.content.features.label}>
              {featuresOpened ? 'View pricing' : 'Full feature comparison'}
            </div>
            <div {...attrs.content.features.table}>
              <FeaturesTable
                isOpen={featuresOpened}
                scrollUp={scrollToTheTop}
                paymentPeriod={toggleActive ? 'yearly' : 'monthly'}
                isMobile={windowWidth <= 900}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
