import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { GoogleCallback } from './pages/CreateAccount/GoogleCallback/GoogleCallback';
import { ROUTES } from './routes';
import { Signup } from './pages/SelfSignup/Signup/Signup';
import { AddExtension } from './pages/SelfSignup/AddExtension/AddExtension';
import { SelfSIgnupBg } from './pages/SelfSignup/SelfSIgnupBg';
import { Pricing } from './pages/SelfSignup/Pricing/Pricing';
import { PaymentDetails } from './pages/SelfSignup/PaymentDetails/PaymentDetails';
import { Processing } from './pages/SelfSignup/Processing/Processing';
import { SignupRedirectPage } from './pages/SelfSignup/SignupRedirectPage/SignupRedirectPage';

interface RouteI {
  routeName: string;
  path: string;
  Component: React.FC;
}

interface RoutingI {
  [key: string]: RouteI;
}

export const SelfSignupRoutes: RoutingI = {
  CREATE_ACCOUNT_REDIRECT: {
    routeName: 'Sign up redirect page',
    path: ROUTES.selfSignup.signup,
    Component: SignupRedirectPage,
  },
  CREATE_ACCOUNT: {
    routeName: 'Sign up',
    path: ROUTES.selfSignup.homePage,
    Component: Signup,
  },
  GOOGLE_CALLBACK_SIGNUP: {
    routeName: 'Google callback',
    path: ROUTES.selfSignup.googleSignup,
    Component: GoogleCallback,
  },
  PRICING: {
    routeName: 'Pricing',
    path: ROUTES.selfSignup.pricing,
    Component: Pricing,
  },
  PAYMENT_DETAILS: {
    routeName: 'Payment details',
    path: ROUTES.selfSignup.paymentDetails,
    Component: PaymentDetails,
  },
  PROCESSING: {
    routeName: 'Processing',
    path: ROUTES.selfSignup.processing,
    Component: Processing,
  },
  ADD_EXTENSION: {
    routeName: 'Add extension',
    path: ROUTES.selfSignup.addExtension,
    Component: AddExtension,
  },
};

const getRoutes = () =>
  Object.values(SelfSignupRoutes).map(({ path, Component }) => (
    <Route key={path} exact path={path}>
      <Component />
    </Route>
  ));

export const SelfSignupRouter: React.FC = (): JSX.Element => {
  const router = getRoutes();
  return (
    <TransitionGroup>
      <SelfSIgnupBg>
        <Switch>
          {router}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </SelfSIgnupBg>
    </TransitionGroup>
  );
};
