import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';

import { EXTENSION_ID } from '../../constants/config';
import {
  signOut,
  EXTENSION_MESSAGE_LOGOUT,
} from '../../store/login/login.actions';
import { ExtensionMessageLogout } from '../../store/login/login.types';
import { ROUTES } from '../../routes';

export const Logout: React.FC = (): JSX.Element => {
  const { token, refresh } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chrome?.runtime) {
      const message: ExtensionMessageLogout = {
        type: EXTENSION_MESSAGE_LOGOUT,
        payload: null,
      };

      chrome?.runtime?.sendMessage(EXTENSION_ID, message, () => {});
    }

    if (token || refresh) {
      dispatch(signOut());
    }

    if (!token || !refresh) {
      window.location.href = ROUTES.selfSignup.homePage;
    }
  });

  return <div />;
};
