import React, { useEffect } from 'react';
import ReactGa4 from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setGoogleAnalyticsInitializedSuccess } from '../../store/auth/auth.actions';
import { GA4_MEASUREMENT_ID } from '../../constants/config';
import { User } from '../../store/login/types';

export const GoogleAnalytics: React.FC = (): null => {
  const { isGoogleAnalyticsInitialized } = useSelector(
    (state: RootState) => state.auth
  );

  const { user } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isGoogleAnalyticsInitialized) {
      ReactGa4.initialize(GA4_MEASUREMENT_ID);
      dispatch(setGoogleAnalyticsInitializedSuccess());
    }
  }, [isGoogleAnalyticsInitialized, dispatch]);

  useEffect(() => {
    if (user) {
      const { userId, orgId }: User = user;

      ReactGa4.set({
        org_Id: orgId,
        user_Id: userId,
      });
    }
    // eslint-disable-next-line
  }, [user?.orgId, user?.userId]);

  return null;
};
